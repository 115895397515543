
import './styles/app.scss';

// automatically add all images to the manifest.json
const imagesCtx = require.context('./images', false, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesCtx.keys().forEach(imagesCtx);

document.addEventListener('DOMContentLoaded', () => {
    const orderForCheckbox = document.querySelector('div.isotope-control-order-for input[type="checkbox"]');

    if (orderForCheckbox) {
        const orderForCheckboxDependencies = [...document.querySelectorAll('div.isotope-control-order-for-dependency')];

        function toggleDependencies() {
            orderForCheckboxDependencies.forEach(dependency => {
                if (orderForCheckbox.checked) {
                    dependency.classList.remove('invisible');
                    dependency.querySelector('input').required = true;
                } else {
                    dependency.classList.add('invisible');
                    dependency.querySelector('input').required = false;
                }
            });
        }

        orderForCheckbox.addEventListener('change', toggleDependencies);
        toggleDependencies();
    }
});
